import React from 'react';
import PropTypes from 'prop-types';
import Zoom from 'react-reveal/Zoom';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Button from 'common/src/components/Button';
import Card from 'common/src/components/Card';
import Image from 'common/src/components/Image';
import FeatureBlock from 'common/src/components/FeatureBlock';
import Container from 'common/src/components/UI/Container';
import CampaignPlannerWrapper, { SectionObject } from './dataStandard.style';
import TextHr from 'common/src/components/textLine';
import ImageBg from 'common/src/assets/image/saas/visitor_bg.png';
import DataStandardImg from 'common/src/assets/image/saas/standardize_data.png';
import Fade from 'react-reveal/Fade';
import {
  TopBootstrap,
  BottomBootstrap,
} from 'common/src/components/bootstrapView/index.style';
const CampaignPlanner = ({
  title,
  description,
  textArea,
  imageWrapper,
  btnStyle,
  sectionSubTitle,
  handleOpenDemoModal,
}) => {
  const ImageView = () => (
    <SectionObject>
      <Card className="objectWrapper" {...imageWrapper}>
        <Zoom>
          <Image
            src={ImageBg}
            alt="BgImage"
            style={{ maxWidth: '100%', transform: 'rotateY(180deg)' }}
          />
        </Zoom>
        <Card className="dashboardWrapper" {...imageWrapper}>
          <Fade right delay={90}>
            <img
              src={DataStandardImg}
              alt="data standard"
              style={{
                boxShadow: '7px 11px 23px 2px grey',
                borderRadius: '5px',
              }}
            />
          </Fade>
        </Card>
      </Card>
    </SectionObject>
  );
  return (
    <CampaignPlannerWrapper id="visitorSection">
      <TopBootstrap>
        <ImageView />
      </TopBootstrap>
      <Container>
        <Box {...textArea}>
          <FeatureBlock
            title={
              <>
                <TextHr />
                <Text content="STANDARDIZE DATA" {...sectionSubTitle} />
                <Heading
                  content={
                    <>
                      <TopBootstrap>
                        Great Analytics starts with
                        <br /> Clean Data.
                      </TopBootstrap>
                      <BottomBootstrap>
                        Great Analytics <br />
                        starts with
                        <br /> Clean Data.
                      </BottomBootstrap>
                    </>
                  }
                  {...title}
                />
              </>
            }
            description={
              <Text
                content="Leverage indept data standardization tools for a clean database. Not enough fields? We provide up to 100 custom fields per data type."
                {...description}
              />
            }
            button={
              <Button
                title="SEE LIVE"
                {...btnStyle}
                onClick={handleOpenDemoModal}
              />
            }
          />
        </Box>
      </Container>
      <BottomBootstrap>
        <ImageView />
      </BottomBootstrap>
    </CampaignPlannerWrapper>
  );
};

CampaignPlanner.propTypes = {
  title: PropTypes.object,
  description: PropTypes.object,
  btnStyle: PropTypes.object,
};

CampaignPlanner.defaultProps = {
  textArea: {
    width: ['100%', '100%', '45%'],
    ml: [0, 0],
  },
  sectionSubTitle: {
    as: 'span',
    display: 'block',
    textAlign: 'left',
    fontSize: '14px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: '#5268db',
    mb: '10px',
  },
  imageWrapper: {
    boxShadow: 'none',
    marginTop: ['2rem', '3.5rem'],
  },
  title: {
    fontSize: ['20px', '26px', '26px', '36px', '48px'],
    fontWeight: '400',
    color: '#2a313c',
    letterSpacing: '-0.010em',
    mb: '20px',
    maxWidth: ['100%', '100%', '100%', '440px', '440px'],
    lineHeight: '1.5',
  },
  description: {
    fontSize: '16px',
    color: '#343d48cc',
    lineHeight: '1.75',
    mb: '33px',
    maxWidth: ['100%', '100%', '100%', '440px', '440px'],
  },
  btnStyle: {
    minWidth: '156px',
    fontSize: '14px',
    fontWeight: '500',
    color: '#fff',
    borderRadius: '4px',
    pl: '22px',
    pr: '22px',
    colors: 'primaryWithBg',
  },
};

export default CampaignPlanner;
